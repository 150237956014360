import React, { Component } from 'react'
import { Box } from '@material-ui/core'
import { Link } from 'gatsby-theme-material-ui'
import { withStyles } from '@material-ui/core/styles'


class Footer extends Component {
    render(){
        const { classes } = this.props
        return (
            <footer>
                <Box className={classes.container}>
                    <Box className={classes.linkContainer}>
                        <Box className={classes.linkBox}><Link className={classes.link} to="privacy-policy">PRIVACY POLICY</Link></Box>
                        <Box className={classes.linkBox}><Link className={classes.link} to="terms-of-service">TERMS OF SERVICE</Link></Box>
                        <Box className={classes.linkBox}><Link className={classes.link} href="mailto:contact@spark.dj">CONTACT US</Link></Box>
                    </Box>
                    <Box display="flex" flex={1} />
                    <Box className={classes.link} paddingRight="20px">© {new Date().getFullYear()} Spark DJ, Inc.</Box>     
                </Box>
            </footer>
        )
    }
}

const styles = theme => ({
    container: {
        display: 'flex',
        height: '5vh',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#232534',
    },
    linkContainer: {
        display: 'flex',
        width: '420px',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft:'20px',
    },
    linkBox: {
        display: 'flex',
        alignItems: 'center',
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        fontFamily: theme.typography.h6.fontFamily,
        fontSize: '0.800rem',
        fontWeight: theme.typography.fontWeightMedium,
        textDecoration: 'none',
    },
})

export default withStyles(styles)(Footer)